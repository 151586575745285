import { $post } from '@/api/http.js'
import common from "@/api/common.js"
import tool from '@/util/tool.js'
import moment from 'moment'
import router from '../router'
import siteinfo from '@/util/global.js'
class Land{

    /**
     * 获取土地分类信息
     * @param {number} page 
     * @param {number} limit 
     * @param {object} search 
     * @param {function} fn 
     */
    getLandCategroy(page,limit,search=null,fn){
        let param = { page,limit}
        if( search && search.key) param.key = search.key
        if( search && search.shop_id) param.shop_id = search.shop_id
        common.getDataList("getLandCategoryList",param).then(res=>{
            fn(res)
        })
    }

    /**
     * 新增或编辑土地分类信息
     * @param {object} data 
     * @param {function} fn 
     * @returns 
     */
    addOrEditCategory(data,fn){
        if( !tool.returnMessage(data.name,'请输入分类名称')) return
        if( !data.id ) delete data.id
        $post("addOrEditLandCategory",data).then(res=>{
            tool.message("保存成功")
            fn(res)
        })
    }

    /**
     * 获取土地列表信息
     * @param {number} page 
     * @param {number} limit 
     * @param {object} extra 
     * @param {function} fn 
     */
    getLandList( page,limit,extra,fn){
        let param = { page,limit } , api = "land2_getLandList"
        if( extra ){
            if( extra.key ) param.key = extra.key

            //获取多商户的土地信息
            if( extra.shop_id ) {
                param.shop_id = extra.shop_id
                api = "getShopLandList"
            }
        }
        common.getDataList(api,param).then(res=>{
            fn(res)
        })
    }

    /**
     * 添加、编辑土地信息(旧)
     * @param {object} land 基本信息
     * @param {object} landSpec  规格信息
     * @param {object} landDeliverCycle 配送周期
     * @param {function} fn 
     */
     addOrEditLand(land,landSpec,landDeliverCycle,fn){
        $post("createOrUpdateLand",{land,landSpec,landDeliverCycle}).then(res=>{
            tool.message("保存成功")
            fn(res.data)
        })
    }
    /**
     * 新增编辑土地信息
     * @param {object} land 
     * @param {object} landSpec 
     */
    addOrEditLandV2(land,landSpec){
        if( !land.id) delete land.id
        let param = { land,landSpec }
        $post("land2_setLand",param,true,2,true,true).then(()=>{
            tool.message("保存成功")
            router.go(-1)
        })
    }

    /**
     * 获取土地详情
     * @param {object} param 
     * @param {array} keys 
     * @param {function} fn 
     */
    getLandDetail(param,fn){
        $post("land2_getLandDetail",param).then(res=>{
            fn(res.data)
        })
    }

    /**
     * 删除土地信息
     * @param {number} id 
     * @param {function} fn 
     * @returns 
     */
    deleteLand(id,fn){
        if( !id ) return
        tool.confirm("确认删除该土地的信息吗？").then(()=>{
            $post("land2_delLand",{id}).then(res=>{
                tool.message("删除成功")
                fn(res.data)
            })
        }).catch(()=>{})
    }

    /**
     * 获取种子列表数据
     * @param {number} page 
     * @param {number} limit 
     * @param {object} search 
     * @param {function} fn 
     */
    getSeed(page,limit,search,fn){
        let param = { page,limit}
        if( search && search.key ) param.key = search.key
        if( search && search.shop_id ) param.shop_id = search.shop_id
        common.getDataList('land2_getSeedList',param).then(res=>{
            fn(res)
        })
    }

    /**
     * 删除种子信息
     * @param {number} id 
     * @param {function} fn 
     * @returns 
     */
    deleteSeed(id,fn){
        if( !id || id.length == 0 ){
            tool.message("请选择要删除的种子")
            return
        }
        tool.confirm("确认要删除种子信息吗？").then(()=>{
            $post("land2_delSeed",{id}).then(res=>{
                tool.message("删除成功")
                fn(res.data)
            })
        }).catch(()=>{})
    }
    
    /**
     * 设置种子状态为热门
     * @param {number} id 
     * @param {function} fn 
     * @returns 
     */
    setSeetHot(id,fn){
        if( !id || id.length == 0 ){
            tool.message("请选择要操作的种子",'warning')
            return
        }
        $post("land2_setSeedHot",{id}).then(res=>{
            tool.message("操作成功")
            fn(res.data)
        })
    }

    /**
     * 获取种子分类信息
     * @param {*} fn 
     */
    getSeedType(fn){
        $post('getSeedCateList').then(res=>{
            fn(res.data)
        })
    }

    /**
     * 保存种子分类信息
     * @param {object} param 
     * @param {function} fn 
     * @returns 
     */
    addOrEditSeedType(param,fn){
        if( !tool.returnMessage(param.name,'请填写分类名称')) return
        if( !param.id) delete param.id
        $post("seedCateAddOrEdit",param).then(res=>{
            tool.message("保存成功")
            fn(res.data)
        })
    }

    /**
     * 添加或编辑种子
     * @param {object} param 
     * @returns 
     */
    addOrEditSeed(param){
        if( !tool.returnMessage(param.send_name,'请输入种子名称')) return
        if( !tool.returnMessage(param.price,'请输入种子价格')) return
        if( !tool.returnMessage(param.low_count,'请输入最少种植面积')) return
        if( !param.id ) delete param.id

        if( param.send_slide.length > 0 ) param.cover = param.send_slide[0]
        $post("land2_setSeed",param).then(()=>{
            tool.message("保存成功")
            router.go(-1)
        })
    }

    /**
     * 获取种子详情
     * @param {number} id 
     * @param {array} keys 
     * @param {function} fn 
     */
    getSeedDetail(id,keys,fn){
        $post('land2_getSeedDetail',{id}).then(res=>{
            if( !keys ) fn(res.data.seed)
            if( keys ) fn( tool.filterForm(keys,res.data.seed))
        })
    }

    /**
     * 获取土地订单列表信息
     * @param {number} page 
     * @param {number} limit 
     * @param {object} search 
     * @param {function} fn 
     */
    getLandOrder(page,limit,search,fn){
        let param = { page,limit}
        switch(search.status){
            case '1':
                param.pay_status =1
                param.is_confirm =0
                param.is_cancel =0
                param.is_refund =0
                break;
            case '2':
                param.pay_status = 1
				param.is_expire = 1
				param.is_cancel = 0
				param.is_confirm = 0
				param.is_refund =0
                break;
            case '3':
                param.is_confirm = 1
				param.is_cancel = 0
				param.pay_status = 1 
				param.is_refund =0
                break;
            case '4':
                param.pay_status = 0
				param.is_cancel = 0
                break;
            case '5':
				param.is_cancel = 1
                break;
            default:
                break;
        }
        if( search.order_number ) param.order_number = search.order_number
        if( search.user_name ) param.user_name = search.user_name
        if( search.land_id ) param.land_id = search.land_id
        if( search.spec_id ) param.spec_id = search.spec_id
        common.getDataList("land2_getLandOrderList",param).then(res=>{
            fn(res)
        })
    }

    /**
     * 获取土地订单详情
     * @param {number} id 
     * @param {function} fn 
     */
    getLandOrderDetail(id,fn){
        $post("land2_getLandOrderDetail",{id}).then(res=>{
            fn(res.data)
        })
    }

    /**
     * 土地订单操作
     * @param {number} id 订单id
     * @param {string} op  confirm=终止租赁、refund_yes=退款、cancel=取消订单 
     * @param {string} title 
     * @param {function} fn 
     */
    handleLandOrder(id,op,title,fn){
        tool.confirm(title).then(()=>{
            $post('land2_setLandOrderStatus',{id,op}).then(res=>{
                tool.message("操作成功")
                fn(res.data)
            })
        }).catch(()=>{})
    }

    /**
     * 获取土地服务信息
     * @param {number} page 
     * @param {number} limit 
     * @param {function} fn 
     */
    getLandServer(page,limit,extra,fn){
        let param = { page,limit}
        if( extra && extra.shop_id ) param.shop_id = extra.extra
        common.getDataList("land2_getLandSerList",{page,limit}).then(res=>{
            fn(res)
        })
    }

    /**
     * 获取地块绑定的服务信息
     * @param {number}} land_id 
     * @param {function} fn 
     */
    getLandServerById(land_id,fn){
        $post("getLandServers",{ land_id}).then(res=>{
            fn(res.data)
        })
    }

    /**
     * 获取土地服务详情
     * @param {number} id   土地服务id
     * @param {object} keys 需要的字段
     * @param {function} fn 
     */
    getLandServerDetail(id,keys=null,fn){
        $post("land2_getLandSerDetail",{id}).then(res=>{
            if( keys ){
                let data = tool.filterForm(keys,res.data.ser)
                fn(data)
                return
            }
            fn(res.data)
        })
    }

    /**
     * 新增或编辑土地服务信息
     * @param {object} param 
     * @returns 
     */
    addOrEditLandServer(param){
        if( !param.id ) delete param.id
        if( !tool.returnMessage(param.name,'请输入服务名称')) return
        if( !tool.returnMessage(param.summary,'请输入服务说明')) return
        if( !tool.returnMessage(param.price,'请输入服务价格')) return
        $post("land2_setLandSer",param).then(()=>{
            tool.message("保存成功")
            router.go(-1)
        })
    }

    /**
     * 删除服务
     * @param {number} id 
     * @param {function} fn 
     */
    deleteLandServer(id,fn){
        tool.confirm("确认删除服务信息吗?").then(()=>{
            $post("land2_delLandSer",{id}).then(res=>{
                tool.message("删除成功")
                fn(res.data)
            })
        }).catch(()=>{})
    }



    /**
     * 设置土地订单信息
     * @param {string} op   change-server=设置服务 change-inspector=设置巡检员
     * @param {number} id   订单id
     * @param {object} extra 其他参数
     * @param {function} fn 
     * @returns 
     */
    setLandOrder(op,id,extra,fn){
        let param = { id}
        //设置巡检员
         if( op =='change-inspector'){
            if( !tool.returnMessage(extra.user_id,'请选择巡检员')) return
            param = { order_id:id,user_id:extra.user_id}
            $post("changgeAdminByLandOrder",param).then(res=>{
                tool.message("操作成功")
                fn(res.data)
            })
            return
        }

        //设置服务
        if( op == 'change-server') {
            if( !tool.returnMessage(extra.ser_id,'请选择服务类型')) return
            param.ser_id = extra.ser_id
        }

        //设置到期时间
        if( op =='change-time' ){
            if( !tool.returnMessage(extra.expire_date,'请选择租赁到期时间')) return
            param.expire_date = moment(extra.expire_date).format("YYYY-MM-DD")
        }

        $post("land2_setLandOrder",param).then(res=>{
            tool.message("操作成功")
            fn(res.data)
        })
    }


    /**
     * 获取巡检员信息列表
     * @param {number} page 
     * @param {number} limit 
     * @param {object} search 
     * @param {function} fn 
     */
    getInspector(page,limit,search,fn){
        let param = { page,limit}
        if( search.key ) param.key = search.key
        if( search.type ) param.type = search.type  //type=2 工人  1=巡检员
        if( search.status ) param.status = search.status
        if( search.shop_id ) param.shop_id = search.shop_id
        common.getDataList("getInspector",param).then(res=>{
            fn(res)
        })
    }

    /**
     * 添加或编辑工人信息
     * @param {object} param 
     * @param {function} fn 
     * @returns 
     */
    addOrEditInspector(param,fn){
        if( !tool.returnMessage(param.name,'请输入姓名')) return
        if( !tool.returnMessage(param.mobile,'请输入电话')) return
        if( !param.id ) delete param.id

        $post("addOrEditInspector",param).then(res=>{
            tool.message("保存成功")
            fn(res.data)
        })
    }

    /**
     * 删除巡检员或工人信息
     * @param {number} id 
     * @param {function} fn 
     */
    deleteInspector(id,fn){
        tool.confirm("确认删除信息吗？?").then(()=>{
            $post("deleteInspector",{id}).then(res=>{
                tool.message("删除成功")
                fn(res.data)
            })
        }).catch(()=>{})
    }

    /**
     * 获取工人或巡检员授权二维码
     * @param {number} id 
     * @param {function} fn 
     */
    getInspectorWxQrcode(id,fn){
        $post("getQrcodeByInspector",{id}).then(res=>{
            fn(res.data)
        }).catch(()=>{})
    }

    /**
     * 添加配送订单
     * @param {number} order_id 
     * @param {object} param 
     * @param {function} fn 
     */
    addDeliveryOrder(order_id,param,fn){
        if( !tool.returnMessage(param.name,'请输入收货姓名')) return
        if( !tool.returnMessage(param.phone,'请输入收货电话')) return
        if( !tool.returnMessage(param.address,'请输入收货地址')) return
        if( !param.delivery_weight )delete param.delivery_weight
        param.order_id = order_id
        if( param.pre_delivery_time ) param.pre_delivery_time= moment(param.pre_delivery_time).format("YYYY-MM-DD")
        $post("land2_setSendOrder",param).then(res=>{
            tool.message("添加成功")
            fn(res.data)
        })
    }

    /**
     * 获取配送订单信息
     * @param {number} page 
     * @param {number} limit 
     * @param {object} extra 
     * @param {function} fn 
     */
    getDeliveryOrder(page,limit,extra=null,fn){
        let param = { page,limit}
        if(extra && extra.order_id ) param.order_id = extra.order_id
        
        if( extra.order_number ) param.order_number = extra.order_number
        if( extra.name ) param.name = extra.name
        if( extra.phone ) param.phone = extra.phone
        if( extra.address ) param.address = extra.address
        if( extra.express_no ) param.express_no = extra.express_no
        if( extra.express_company ) param.express_company = express_company
        
        if( extra.status == 1 ) {
            param.delivery_status = 0
            param.pay_status = 1
            param.is_refund = 0
            param.is_cancel = 0
        }
        if( extra.status == 2 ) {
            param.delivery_status = 1
            param.pay_status = 1
            param.is_refund = 0
            param.is_cancel = 0
        }
        if( extra.status == 3 ) {
            param.pay_status = 1
            param.delivery_status = 2
            param.is_refund = 0
            param.is_cancel = 0
        }
        if( extra.status == 4 ) {
            param.pay_status = 1
            param.is_refund = 0
            param.is_cancel = 0
        }
        if( extra.status == 5 ) {
            param.pay_status = 0
            param.is_refund = 0
            param.is_cancel = 0
        }
        if( extra.status == 6 ) param.is_refund = 1
        if( extra.status == 7 ) param.is_cancel = 1

        common.getDataList("land2_getSendOrderList",param).then(res=>{
            fn(res)
        })
    }

    /**
     * 获取配送订单详情
     * @param {*} id 
     * @param {*} fn 
     */
    getDeliveryOrderDetail(id,fn){
        $post("land2_getSendOrderDetail",{id}).then(res=>{
            fn(res.data)
            // if( ['5','9','10','1000'].includes(t.detail.runing.order_status) ){
            //     this.show.reback = true  //dada重新下单
            // }
        })
    }

    /**
     * 配送订单操作
     * @param {string} op   refund_yes=退款  confirm = 确认收货 ,cancel=取消配送 ,send = 发货
     * @param {number} id   订单id  op=send时，该参数为接口需要传递的参数
     * @param {string} title 提示
     * @param {function} fn 
     */
    setDeliveryStatus(op,id,title,fn){
        if( op == 'send' ){ //发货操作
            $post('land2_setSendOrderStatus',id).then(res=>fn(res.data))	
            return
        }
        tool.confirm(title).then(()=>{
            $post("land2_setSendOrderStatus",{op,id}).then(res=>{
                tool.message("操作成功")
                fn(res)
            })
        }).catch(()=>{})
    }

    /**
     * 配送订单批量收货
     * @param {array} ids 
     * @param {function} fn 
     * @returns 
     */
    setBatchDeliveryOrderConfirm(ids,fn){
        if( ids.length == 0 ){
            tool.message("请选择要操作的数据",'warning')
            return
        }
        tool.confirm('确认收货吗？').then(()=>{
            $post("land2_setSendOrderStatusBatch",{op,id}).then(res=>{
                tool.message("收货成功")
                fn(res)
            })
        }).catch(()=>{})
    }

    /**
     * 批量发货
     * @param {string} file_url 
     */
    batchSendOrder(file_url){
        $post('land2_importSendOrder',{file_url}).then(res=>{
            tool.message("批量发货成功")
        }).catch(()=>{})
    }

    /**
     * 导出配送订单
     * @param {array} ids 
     * @returns 
     */
    exportDeliveryOrder(ids){
        if( ids.length == 0 ){
            tool.message("请选择要导出的信息","warning")
            return
        }
        let url = siteinfo.siteinfo.siteroot+'/api/admin/land2_exportSendOrder?token='+localStorage.getItem('token');
        
        url+= '&ids='+ids.join(',')
        window.open(url);
    }

    /**
     * 获取土地续费订单
     * @param {number} page 
     * @param {number} limit 
     * @param {object} extra 
     * @param {function} fn 
     */
    getLandRenewOrder(page,limit,extra=null,fn){
        let param = { page,limit}
        if(extra && extra.order_id ) param.order_id = extra.order_id
        common.getDataList("land2_getUpgradeOrderList",param).then(res=>{
            fn(res)
        })
    }

    /**
     * 获取土地订单日志
     * @param {number} order_id 
     * @param {function} fn 
     */
    getLandOrderLog(order_id,fn){
        $post("land2_getLandOrderStatusLog",{id:order_id}).then(res=>{
            fn(res.data)
        })
    }

    /**
     * 获取土地订单巡检记录
     * @param {number} order_id 
     * @param {function} fn 
     */
    getLandOrderInspectorLog(order_id,fn){
        $post("landOrderInspectionRecordList",{id:order_id}).then(res=>{
            fn(res.data)
        })
    }

    /**
     * 获取种植订单信息
     * @param {number} page 
     * @param {number} limit 
     * @param {object} extra 
     * @param {function} fn 
     */
    getPlantOrder(page,limit,extra,fn){
        let param = { page,limit }
        if( extra.order_id ) param.land_order_id = extra.order_id
        if( extra.order_number ) param.order_number = extra.order_number
        if( extra.land_id ) param.land_id = extra.land_id
        if( extra.spec_id ) param.spec_id = extra.spec_id
        if( extra.status == 1 ) {//生长中
            param.is_plant = 1
            param.pay_status = 1
            param.is_confirm = 0
            param.is_cancel == 0
        }
        if( extra.status == 2 ) {	//待播种
            param.is_plant = 0
            param.pay_status = 1
            param.is_cancel = 0
            param.is_ripe = 0
            param.is_confirm = 0
        }
        if( extra.status == 3 ) {//已成熟
            param.pay_status = 1
            param.is_ripe = 1
            param.is_cancel = 0
            param.is_confirm = 0
        }
        if( extra.status == 4 ) {  //待支付
            param.pay_status = 0
            param.is_cancel = 0
        }
        if( extra.status == 5 ) { //已完成
            param.is_confirm = 1
            param.is_cancel = 0
        }
        if( extra.status == 6 ) {	//已取消
            param.is_cancel = 1
        }

        common.getDataList("land2_getPlantOrderList",param).then(res=>{
            fn(res)
        })
    }

    /**
     * 种植订单设置
     * @param {string} op refund_yes=退款
     * @param {number} id 种植订单id
     * @param {string} title 提示语
     * @param {function} fn 
     */
    setPlantOrder(op,id,title,fn){
        tool.confirm(title).then(()=>{
            $post("land2_setPlantOrderStatus",{op,id}).then(res=>{
                tool.message("操作成功")
                fn(res)
            })
        }).catch(()=>{})
    }
    /**
     * 设置种植状态
     * @param {string} op 操作状态
     * @param {Array} ids 操作种植列表
     * @param {function} fn 
     */
    setPlantStatus(op,ids,fn){
        if( !ids || ids.length == 0 ){
            tool.message("请选择种子","warning")
            return
        }
        tool.confirm("确认修改种植的状态吗").then(()=>{
            $post("land2_setPlantStatus",{id:ids,op}).then(res=>{
                tool.message("操作成功")
                fn(res.data)
            }).catch(()=>{})
        }).catch(()=>{})
    }

    /**
     * 设置种植预估产量
     * @param {number} id 
     * @param {number} count 
     * @param {function} fn 
     */
    setPlantOrderYield(id,count,fn){
        $post("setPlantOrderYield",{id,yield:count}).then(res=>{
            tool.message("操作成功")
            fn(res.data)
        })
    }

    /**
     * 获取种植订单详情
     * @param {number} order_id 
     * @param {function} fn 
     */
    getPlantOrderDetail(order_id,fn){
        $post('land2_getPlantOrderDetail',{id:order_id}).then(res=>{
            fn(res.data)
        })
    }

    /**
     * 土地续费升级订单操作
     * @param string} op    refund_yes=退款
     * @param number} id    订单id
     * @param string} title 提示语
     * @param function} fn 
     */
    setLandUpgradOrder(op,id,title,fn){
        tool.confirm(title).then(()=>{
            $post("land2_setUpgradeOrderStatus",{op,id}).then(res=>{
                tool.message("操作成功")
                fn(res)
            })
        }).catch(()=>{})
    }

    /**
     * 获取交换订单列表
     * @param {number} page 
     * @param {number} limit 
     * @param {object} extra 
     * @param {function} fn 
     */
    getLandExchangeOrder(page,limit,extra,fn){
        let param = { page,limit }
        if( extra.key ) param.key = extra.key
        common.getDataList("getExchangeOrderList",param).then(res=>{
            fn(res)
        })
    }

    /**
     * 获取工单列表信息
     * @param {number} page 
     * @param {number} limit 
     * @param {object} extra 
     * @param {function} fn 
     */
    getWorkOrder(page,limit,extra,fn){
        let param = { page,limit}
        if( extra.key ) param.key = extra.key
        if( extra.server_id ) param.server_id = extra.server_id
        if( extra.order_status ) param.order_status = extra.order_status
        common.getDataList("workOrderList",param).then(res=>{
            fn(res)
        })
    }

    /**
     * 指派工单
     * @param {number} user_id 工人userid
     * @param {number} id       工单id
     * @param {function} fn 
     * @returns 
     */
    setWorkOrderWorker(user_id,id,fn){
        if( !tool.returnMessage(user_id,'请选择工人')) return
        $post("workOrderAssignment",{user_id,id}).then(res=>{
            tool.message("设置成功")
            fn(res)
        })
    }
    
    /**
     * 获取土地工单服务信息
     * @param {number} page 
     * @param {number} limit 
     * @param {function} fn 
     */
    getLandWorkServer(page,limit,extra,fn){
        let param = {page,limit}
        if( extra && extra.shop_id ) param.shop_id = extra.shop_id
        common.getDataList("landWorkOrderServerList",param).then(res=>{
            fn(res)
        })
    }

    /**
     * 删除工单服务
     * @param {number} id 
     * @param {function} fn 
     */
    deleteLandWorServer(id,fn){
        tool.confirm("确认删除服务信息吗？").then(()=>{
            $post("deleteWorkOrderServer",{id}).then(res=>{
                tool.message("删除成功")
                fn(res)
            })
        }).catch(()=>{})
    }

    /**
     * 删除工单服务操作项
     * @param {number} id 
     * @param {function} fn 
     */
    deleleLandWorkServerItem(id,fn){
        if( id ) return
        tool.confirm("确认删除操作项信息吗？").then(()=>{
            $post("deleteWorkOrderServerDetailsModel",{id}).then(res=>{
                tool.message("删除成功")
                fn(res)
            })
        }).catch(()=>{})
    }

    /**
     * 添加工单服务
     * @param {number} param 
     */
    addOrEditLandWorkServer(param){
        if( !tool.returnMessage(param.title,'请输入操作类型名称')) return
        if( !tool.returnMessage(param.summary,'请输入操作类型描述')) return
        if( !tool.returnMessage(param.images,'请上传封面')) return
        if( !param.id ) delete param.id
        $post("landWorkOrderServerAddOrEdit",param).then(res=>{
            tool.message("保存成功")
            router.go(-1)
        })
    }

    /**
     * 获取工单服务详情
     * @param {number} id 
     * @param {array} keys 
     * @param {function} fn 
     */
    getLandWorkServerDetail(id,keys=null,fn){
        $post("landWorkOrderDetails",{id}).then(res=>{
            if( keys ){
                let data = tool.filterForm(keys,res.data)
                data.workorderdetails = res.data.details
                fn(data)
                return
            }
            fn(res.data)
        })
    }

    /**
     * 获取种植状态
     * @param {number}} plant_id 
     * @param {function} fn 
     */
    getPlantStatus(plant_id,fn){
        $post('getPlantStatusList',{plant_id}).then(res=>{
            fn(res.data || [])
        })
    }

    /**
     * 添加或编辑种植状态
     * @param {object}} param 
     * @param {function} fn 
     */
    addOrEditPlantStatus(param,fn){
        if( typeof param.plant_id == 'object' ){	//批量添加
            $post('land2_addPlantStatusBatch',param).then(res=>{
                fn(res.data)
            })
        }else{
            $post('land2_addPlantStatus',param).then(res=>{
                tool.message("保存成功");
                fn(res.data)
            })
        }

    }
}

const landModel = new Land()

export default landModel